<template>

  <section class="forms">
    <div class="page-header">
      <h3 class="page-title">
        Upload GeoJSON
      </h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:void(0);">Plans</a></li>
          <li class="breadcrumb-item active" aria-current="page">Process GeoJSON</li>
        </ol>
      </nav>
    </div>
    <ValidationObserver v-slot="{ handleSubmit, invalid }" ref="form">
    <form @submit.prevent="handleSubmit(onSubmit)" class="forms-sample">
    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">GeoJSON</h4>
            <p class="card-description">
              please upload a GeoJSON file .geojson and .zip for multiple files.
            </p>
            <div class="row">
              <div class="col-md-6">
                <ValidationProvider name="city" rules="required">
                  <b-form-group slot-scope="{ errors }" label-for="city" horizontal label="Cities">
                    <b-form-select id="city" :disabled="!listCities.length" v-model="city_id" :options="listCities"/>
                    <p>{{ errors[0] }}</p>
                  </b-form-group>
                </ValidationProvider>
              </div>
              <div class="col-md-6">
                <ValidationProvider name="transactions" rules="required">
                  <b-form-group slot-scope="{ errors }" label="Import File" label-for="transactions">
                    <b-form-file v-model="file" id="transactions" :state="Boolean(file)" placeholder="Choose a file..."></b-form-file>
                    <p>{{ errors[0] }}</p>
                  </b-form-group>
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <b-form-group class="float-right">
              <b-button type="submit" variant="success" :disabled="invalid" class="mr-2">{{ create_text }}</b-button>
              <b-button type="button" @click="resetForm" variant="light">Clear</b-button>
            </b-form-group>
          </div>
        </div>
      </div>
    </div>
    </form>
    </ValidationObserver>
    <vue-snotify></vue-snotify>
  </section>

</template>

<script lang="js">
/* eslint-disable no-debugger, no-console */

import { ValidationObserver } from 'vee-validate'

export default {
  name: 'transactionAddNew',
  data () {
    return {
      file: null,
      create_text: "Upload GeoJSON",
      city_id: "",
    }
  },
  components: {
    ValidationObserver
  },
  computed: {
    listCities() {
      return this.$store.getters['city/listCities']
    },
  },
  methods: {
    onSubmit() {
      this.create_text = "Uploading"
      let form_data = new FormData()

      //iterate and append
        form_data.append('file', this.file, this.file.name)

      form_data.append('city_id', this.city_id)

      this.$store.dispatch('plan/uploadGeoJSON', form_data)
      .then((res) => {
        this.create_text = "Upload GeoJSON"
        this.resetForm()
        this.$snotify.success(res.data.message, {
          timeout: 2000,
          showProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true
        })
      })
      .catch((error) => {
        this.create_text = "UPload GeoJSON"
        let message = "Something went wrong!"
        if(error.message)
          message = error.response.data.message

        this.$snotify.warning(message, {
          timeout: 2000,
          showProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true
        })
      })
    },
    resetForm() {
      this.file = null
      this.city_id = ""
      this.$nextTick(() => {
        this.$refs.form.reset();
      })
    },
    fetchCities() {
      const payload = {
        meta: {
          page: 1,
          paginate: 200
        },
      }
      this.$store.dispatch("city/fetchCities", payload)
    }
  },
  created() {
    this.fetchCities()
  }
}
</script>
